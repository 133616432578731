import React, { useState, useEffect } from "react";
import "./privacyPopup.css";

const PrivacyPolicyPopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const privacyAccepted = localStorage.getItem("privacyAccepted");
    if (!privacyAccepted) {
      setTimeout(() => setIsVisible(true), 1000); // Задержка перед появлением
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("privacyAccepted", "true");
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="popup-container">
      <div className="popup-content">
        <p>
          Мы используем файлы cookie для улучшения работы сайта. Нажимая "Согласиться", вы принимаете условия нашей{" "}
          <a href="/privacy-policy" className="popup-link">
            Политики конфиденциальности
          </a>
          .
        </p>
        <div className="popup-button-container">
          <button onClick={handleAccept} className="popup-button">
            Согласиться
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyPopup;