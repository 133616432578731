import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './header.css';

export default function Header() {
  useEffect(() => {
    const header = document.querySelector('.header');
    setTimeout(() => {
      header.classList.add('animate-header');
    }, 100); // Задержка, чтобы эффект начался после загрузки
  }, []);

  const scrollToOutcomeSection = () => {
    const outcomeSection = document.querySelector('.outcome-section');
    if (outcomeSection) {
      outcomeSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTestingSection = () => {
    const testingSection = document.querySelector('.sc-testing');
    if (testingSection) {
      testingSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header className='header'>
      <div className='container'>
        <div className='logo'>Helios</div>
        <nav className='nav-links'>
          <button className="scroll-button" onClick={scrollToOutcomeSection}>
            Узнать больше
          </button>
          <button className="scroll-button" onClick={scrollToTestingSection}>
            Протестировать ИИ-менеджера
          </button>

          <Link to="/login">Личный кабинет</Link>
        </nav>
      </div>
    </header>
  );
}