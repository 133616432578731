import React from "react";
import { useNavigate } from "react-router-dom";

const HeaderBack = () => {
  const navigate = useNavigate();

  return (
    <header style={headerStyle}>
      <button onClick={() => navigate(-1)} style={buttonStyle}>
        Назад
      </button>
    </header>
  );
};

const headerStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.2)", // Прозрачный фон
  height: "60px",
  display: "flex",
  alignItems: "center",
  padding: "0 10px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  zIndex: -834752847502348,
  backdropFilter: "blur(5px)", // Размытие для эстетичного эффекта
};

const buttonStyle = {
  backgroundColor: "#b34727", // Цвет кнопки
  color: "#fff", // Цвет текста кнопки
  border: "none",
  padding: "5px 10px", // Маленький размер кнопки
  borderRadius: "5px",
  cursor: "pointer",
  fontSize: "14px",
  marginLeft: "10px", // Отступ от левого края
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
  zIndex: 823745028347502384,
};

export default HeaderBack;