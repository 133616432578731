import React from 'react';
import './outcomeSection.css';

export default function OutcomeSection() {
  const tableData = [
    { metric: "Эффективность", improvement: 70 },
    { metric: "Скорость ответа", improvement: 80 },
    { metric: "Конверсия диалога", improvement: 90 },
  ];

  return (
    <section className="outcome-section">
      <div className="container">
        <h2 id="end">Что вы получите в итоге</h2>
        <ul>
          <li>
            <strong>Круглосуточную поддержку клиентов:</strong> ИИ-менеджер работает 24/7 без выходных и перерывов, обеспечивая мгновенные ответы на запросы клиентов в любое время суток.
          </li>
          <li>
            <strong>Высокую скорость обработки заявок:</strong> Благодаря возможности обрабатывать множество заявок одновременно, ИИ-менеджер обеспечивает быстрый и качественный сервис, что увеличивает удовлетворенность клиентов.
          </li>
          <li>
            <strong>Персонализированный подход:</strong> Стиль общения ИИ-менеджера неотличим от стиля общения человека, что подтверждено международными исследованиями. Ваши клиенты будут получать персонализированные ответы, соответствующие их запросам.
          </li>
          <li>
            <strong>Автоматизация процессов:</strong> ИИ-менеджер выполняет задачи, которые вы ему назначите, такие как отправка горячих лидов через Telegram-бота, что позволяет вашим сотрудникам сосредоточиться на более сложных и творческих задачах.
          </li>
          <li>
            <strong>Увеличение продаж:</strong> Благодаря мгновенной реакции на запросы клиентов и персонализированному подходу, ИИ-менеджер помогает удерживать клиентов и увеличивать количество покупок, что в конечном итоге приводит к росту доходов вашего бизнеса.
          </li>
        </ul>
        <h2 className="table-title">Показатели производительности ИИ-менеджеров</h2>
        <table className="performance-table">
          <thead>
            <tr>
              <th>Метрика</th>
              <th>Улучшение (%)</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, index) => (
              <tr key={index}>
                <td>{row.metric}</td>
                <td>{row.improvement}%</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>Интеграция ИИ-менеджера – это шаг к повышению эффективности вашей сферы продаж и улучшению общего клиентского опыта.</p>
      </div>
    </section>
  );
}
