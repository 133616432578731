import React from "react";
import "./productsTable.css";

const ProductsTable = () => {
  const products = [
    {
      name: "ИИ-менеджер по продажам",
      features: [
        "Ведет клиента от вопросов до финальных расчетов",
        "Презентует услуги и составляет предложения",
        "Передает данные через Telegram-бот",
      ],
      price: "25 000 ₽",
      link: "https://t.me/heliosai_official_bot",
    },
    {
      name: "ИИ-обработчик лидов",
      features: [
        "Собирает и уточняет предпочтения клиентов",
        "Выделяет самые заинтересованные заявки",
        "Передает данные через Telegram-бот",
      ],
      price: "20 000 ₽",
      link: "https://t.me/heliosai_official_bot",
    },
    {
      name: "ИИ-анализ звонков",
      features: [
        "Транскрибирует звонки с вашей CRM",
        "Сохраняет записи и ключевые выводы",
        "Интеграция с Google Таблицей",
      ],
      price: "45 000 ₽",
      link: "https://t.me/heliosai_official_bot",
    },
  ];

  return (
    <div className="products-grid-container">
      <h1 className="products-title">Наши продукты</h1>
      <div className="products-grid">
        {products.map((product, index) => (
          <div className="product-card" key={index}>
            <h2 className="product-name">{product.name}</h2>
            <ul className="product-features">
              {product.features.map((feature, i) => (
                <li key={i}>{feature}</li>
              ))}
            </ul>
            <p className="product-price">{product.price}</p>
            <a
              href={product.link}
              target="_blank"
              rel="noopener noreferrer"
              className="product-link"
            >
              Подробнее
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProductsTable;