import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './comps/header/Header';
import Footer from './comps/footer/Footer';
import Banner from './comps/banner/Banner';
import CardBlock from './comps/card/CardBlock';
import InfoBlock from './comps/info/InfoBlock';
import WriteUsBlock from './comps/writeUs/WriteUsBlock';
import TestingBlock from './comps/testing/TestingBlock';
import FormBlock from "./comps/form/FormBlock";
import FeedbackForm from "./comps/feedbackForm/FeedbackForm";
import OutcomeSection from "./comps/outcomeSection/OutcomeSection";
import ClientsSection from "./comps/clients/ClientsSection";
import Login from './comps/login/Login';
import Register from './comps/register/Register';
import Dashboard from './comps/dashboard/Dashboard';
import Dialogues from './comps/dialogues/Dialogues';
import AdminPanel from './comps/adminPanel/AdminPanel'; // Import the AdminPanel component
import VerifyEmail from "./comps/verify/Verify";
import PrivacyPolicy from "./comps/privacyPolicy/PrivacyPolicy";
import PrivacyPopup from "./comps/privacyPopup/PrivacyPopup";
import ProductsTable from "./comps/productsTable/ProductsTable";
import HeaderBack from "./comps/headerBack/HeaderBack";
function App() {
  return (
    <Router>
      <div id='wrapper'>

        <Routes>
          <Route path="/" element={<>
            <Header />
            <Banner />
            <CardBlock />
            <InfoBlock />
            <TestingBlock />
            <WriteUsBlock />
            <FormBlock />
            <OutcomeSection />
            <ClientsSection />
            <ProductsTable />
            <Footer />
            <PrivacyPopup />
          </>} />

          <Route path="/login" element={<>
            <HeaderBack />
            <Login />
          </>} />
          <Route path="/register" element={<>
            <HeaderBack />
            <Register />
          </>} />
          <Route path="/dashboard" element={<>
            <HeaderBack />
            <Dashboard />
          </>} />
          <Route path="/clients/:source" element={<>
            <HeaderBack /> <Dialogues />
          </>} />
          <Route path="/admin" element={<>
            <HeaderBack />
            <AdminPanel />
          </>} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>


        </Routes>

      </div>
    </Router>
  );
}

export default App;
